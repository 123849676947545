<template>
  <div class="container">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain pcdd">
        <!-- 数字-->
        <div class="data ">
          <h3>总和、总和过关</h3>
          <ul class="custom_ul">
            <!-- :class="item.selected ? 'selected' : ''" -->
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="(item,index) in rowsData[0].arr" :key="item.ResultID">
              <ol class="td_name" style="width:55px" :style="{fontSize:index==4?'10px':'13px',textAlign:'center'}">
                {{item.label}}
              </ol>
              <ol class="td_rate">
                <!-- item.Odds -->
                {{ item.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
            <li></li>
          </ul>
        </div>
        <div class="data ">
          <h3>五行</h3>
          <ul class="custom_ul">
            <!-- :class="item.selected ? 'selected' : ''" -->
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="item in rowsData[3].arr" :key="item.ResultID">
              <ol class="td_name" style="width:35px">
                {{item.label}}
              </ol>
              <ol class="td_rate">
                <!-- item.Odds -->
                {{ item.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
        <div class="data">
          <h3>前后和</h3>
          <ul class="custom33">
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="item in rowsData[1].arr" :key="item.ResultID">
              <ol class="td_name" style="width:55px">
                {{item.label}}
              </ol>
              <ol class="td_rate">
                <!---->
                {{
                item.Odds 
                }}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
        <div class="data">
          <h3>单双和</h3>
          <ul class="custom33">
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="item in rowsData[2].arr" :key="item.ResultID">
              <ol class="td_name" style="width:55px">
                {{item.label}}
              </ol>
              <ol class="td_rate">
                <!---->
                {{
                item.Odds 
                }}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>

      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'zhbswx',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        { title: "总和、总和过关", arr: [] },
        { title: "前后和", arr: [] },
        { title: "单双和", arr: [] },
        { title: "五行", arr: [] },
      ],

      labelArr1: [
        "总和大",
        "总和小",
        "总和单",
        "总和双",
        "总和810",
        "总大单",
        "总大双",
        "总小单",
        "总小双",
      ],
      labelArr2: ["前(多)", "后(多)", "前后(和)"],
      labelArr3: ["单(多)", "双(多)", "单双(和)"],
      labelArr4: ["金", "木", "水", "火", "土"],
    };
  },
  computed: {
    numArr1() {
      return this.numArr.filter((num) => num != this.num2 && num != this.num3);
    },
    numArr2() {
      return this.numArr.filter((num) => num != this.num1 && num != this.num3);
    },
    numArr3() {
      return this.numArr.filter((num) => num != this.num1 && num != this.num2);
    },
  },
  components: {
    topTimer,
    yushe,
  },
  watch: {
    num1: {
      immediate: true,
      handler(val) {},
    },
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title == "总和、总和过关" ? "" : item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };

                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        //总和、总和过关
        let arr1 = this.filterData(resAArr, 1867, 1875, this.labelArr1);
        this.rowsData[0].arr = arr1;
        // 前后和
        let arr2 = this.filterData(resAArr, 1876, 1878, this.labelArr2);
        this.rowsData[1].arr = arr2;
        //  单双和
        let arr3 = this.filterData(resAArr, 1879, 1881, this.labelArr3);
        this.rowsData[2].arr = arr3;
        // 五行
        let arr4 = this.filterData(resAArr, 1882, 1886, this.labelArr4);
        this.rowsData[3].arr = arr4;
        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain > .data > ul.custom33 li {
  width: 33.33%;
}

.listmain > .data > ul.custom_ul1 {
  width: 100%;
  height: 52px;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
  /* align-items: flex-start; */
}
.listmain > .data ul.custom_ul li {
  width: 20%;
  height: 30px;
}
.listmain > .data ul.custom_ul {
  width: 100%;
}
.listmain .data ul li {
  width: 25%;
  height: 26px;
}
</style>
